import { useEffect, useState } from "react";

const useResponsiveView = () => {
  const [breakpoints, setBreakpoints] = useState({
    isMobile: window.innerWidth < 768,
    isIpad: window.innerWidth >= 768 && window.innerWidth < 1024,
    isLaptop: window.innerWidth >= 1024 && window.innerWidth < 1600,
    isLaptopBig: window.innerWidth >= 1440 && window.innerWidth < 1599,
    isDesktop: window.innerWidth >= 1600,
  });

  useEffect(() => {
    const updateBreakpoints = () => {
      setBreakpoints({
        isMobile: window.innerWidth < 768,
        isIpad: window.innerWidth >= 768 && window.innerWidth < 1024,
        isLaptop: window.innerWidth >= 1024 && window.innerWidth < 1600,
        isLaptopBig: window.innerWidth >= 1440 && window.innerWidth < 1599,
        isDesktop: window.innerWidth >= 1600,
      });
    };

    window.addEventListener("resize", updateBreakpoints);
    return () => {
      window.removeEventListener("resize", updateBreakpoints);
    };
  }, []);

  return breakpoints;
};

export default useResponsiveView;
