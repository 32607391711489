import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { fetchAPI } from "services/api.Service";
import { QUERY_KEYS } from "utils/Constants/QueryKeys.constants";

// FETCH PRODUCT
export const useFetchProduct = (productParams, pagination) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PRODUCT, productParams, pagination],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.PRODUCT.PRODUCT_API,
        // queryParams: productParams,
        queryParams: { ...productParams, ...pagination },
        data: {},
        signal,
      }),

    // placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    // enabled: !(
    //   productParams.year === "" ||
    //   productParams.make === "" ||
    //   productParams.category === "" ||
    //   productParams.model === "" ||
    //   productParams.subModel === ""
    // ),

    enabled:
      !!productParams.year ||
      !!productParams.make ||
      !!productParams.category ||
      !!productParams.model ||
      !!productParams.subModel ||
      !!productParams.driveType ||
      !!productParams.search,
  });
};

// FETCH PRODUCT BY ID
export const useFetchProductById = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PRODUCT_ADD, id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.PRODUCT.PRODUCT_API}/${id}`,
        queryParams: {},
      }),
    enabled: !!id,
    retry: false,
  });
};

// FETCH PRODUCT FILTER
export const useFetchProductFilter = ({ filterType, productParams }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PRODUCT, filterType, productParams],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.PRODUCT.PRODUCT_FILTER}/values`,
        // queryParams: {},
        queryParams: {
          type: filterType,
          ...productParams,
        },
      }),
    // enabled: !!filterType, //Only fetch when filterType is available
  });
};

// ADD PRODUCT
export const useAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.PRODUCT.PRODUCT_API,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PRODUCT]);
    },
  });
};

// UPDATE PRODUCT
export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.PRODUCT.PRODUCT_API}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PRODUCT]);
    },
  });
};

// DELETE PRODUCT
export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.PRODUCT.PRODUCT_API}/${id}`,
        queryParams: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PRODUCT]);
    },
  });
};

// UPLOAD PRODUCT
export const useUploadProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.PRODUCT.PRODUCT_UPLOAD,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PRODUCT]);
    },
  });
};

// UPLOAD IMAGE
export const useUploadImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.PRODUCT.IMAGE_UPLOAD,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PRODUCT_IMAGE]);
    },
  });
};

// FETCH PRODUCT CATEGORIES
export const useFetchProductCategories = (params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PRODUCT, params],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.PRODUCT.PRODUCT_CATEGORY,
        queryParams: params,
        data: {},
        signal,
      }),

    placeholderData: keepPreviousData,
  });
};
