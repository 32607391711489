import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal, Space } from "antd";
import CustomRow from "components/UiComponents/CustomRow";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomSwitch from "components/UiComponents/CustomSwitch";
import CustomButton from "components/UiComponents/CustomButton";
import CustomGridView from "components/CustomComponents/CustomGridView";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { DISCOUNT_STATUS } from "utils/Constants/Constant";
import { getValueOrPlaceholder } from "utils/Helper/Services.helper";
import { authenticatedRoutes } from "utils/Constants/routes.constant";
import notificationService from "utils/Notification/Notification.service";
import { useDeleteDiscount, useUpdateDiscount } from "hooks/useDiscountApi";
import { PageTitleContext } from "PageTitleContext";
import CircleExclamaion from "assets/SvgFiles/CircleExclamaion";
import TrashIcon from "assets/SvgFiles/trashicon.svg";
import EditIcon from "assets/SvgFiles/editicon.svg";
import ViewIcon from "assets/SvgFiles/ViewIcon";
import "./Discounts.less";

const Discounts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { confirm } = Modal;
  const { setPageTitle } = useContext(PageTitleContext);
  const [deletedItems, setDeletedItems] = useState([]);

  const { mutate: deleteDiscount, isPending: deletingDiscount } =
    useDeleteDiscount();
  const { mutate: updateStatus, isPending: updatingStatus } =
    useUpdateDiscount();

  useEffect(() => {
    setPageTitle(t("discounts"));
  }, [setPageTitle]);

  const columns = [
    {
      title: t("id"),
      key: "id",
      render: (res) => getValueOrPlaceholder(res, "id"),
    },

    {
      title: t("name"),
      key: "name",
      render: (res) => getValueOrPlaceholder(res, "name"),
      className: "bold",
      width: "20%",
    },
    {
      title: t("productCategory"),
      key: "category",
      render: (res) => getValueOrPlaceholder(res, "category"),
    },
    {
      title: t("percentage"),
      key: "percentage",
      render: (res) => <>{`${getValueOrPlaceholder(res, "percentage")}%`}</>,
    },

    {
      title: "Status",
      key: "status",
      className: "status-col",
      render: (res, _, index) => {
        const isChecked = res.status === DISCOUNT_STATUS.ACTIVE;

        return (
          <CustomSwitch
            checked={isChecked}
            onChange={(checked) => {
              const newDataArray = [...(res?.items || [])];
              const updatedItem = {
                ...newDataArray.find((item) => item.id === res.id),
                status: checked,
              };
              newDataArray[
                newDataArray.findIndex((item) => item.id === res.id)
              ] = updatedItem;
              const statusValue = checked
                ? DISCOUNT_STATUS.ACTIVE
                : DISCOUNT_STATUS.INACTIVE;
              handleStatusToggle({ id: res.id, status: statusValue });
            }}
          />
        );
      },
    },

    {
      title: t("action"),
      key: "action",
      className: "action-col",
      render: (res, record) => (
        <Space>
          <CustomButton
            htmlType="default"
            className="action-btn view-icon textWhite"
            onClick={() => {
              navigate(`${authenticatedRoutes.DETAIL}/${res.id} `, {
                state: res,
              });
            }}
          >
            <ViewIcon />
          </CustomButton>

          <CustomButton
            htmlType="default"
            className="action-btn editIcon"
            onClick={() => {
              navigate(`${authenticatedRoutes.EDIT}/${res.id} `, {
                state: res,
              });
            }}
          >
            <img src={EditIcon} alt="Edit" />
          </CustomButton>

          <CustomButton
            htmlType="default"
            className="action-btn deleteIcon"
            onClick={() => discountDeleteModal(res.id)}
          >
            <img src={TrashIcon} alt={t("icon")} />
          </CustomButton>
        </Space>
      ),
    },
  ];

  const discountDeleteModal = (id) => {
    confirm({
      wrapClassName: "logOutModal",
      title: t("areYouSure"),
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: t("youWantToDeleteThisDiscount"),
      okText: t("yes"),
      cancelText: t("cancel"),
      icon: (
        <div className="icon-wrapper">
          <CircleExclamaion />
        </div>
      ),
      async onOk() {
        handleDelete(id);
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <OkBtn />
          <CancelBtn />
        </>
      ),
    });
  };

  const handleDelete = (id) => {
    deleteDiscount(id, {
      onSuccess: () => {
        setDeletedItems((prev) => [...prev, id]); //Deleted items id's to prevent from showing up in listing
        notificationService.success(t("customerDeleted"));
      },
      onError: (error) => {
        notificationService.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : t("errorDeletingCustomer")
        );
      },
    });
  };

  const handleStatusToggle = (id) => {
    discountStatusModal(id);
  };

  const discountStatusModal = (id) => {
    confirm({
      wrapClassName: "logOutModal",
      title: t("areYouSure"),
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: `${t("youWantTo")} ${
        id.status === DISCOUNT_STATUS.ACTIVE ? t("activate") : t("deActivate")
      } ${t("thisDiscount")}`,

      okText: t("yesSure"),
      cancelText: t("noNotNow"),
      icon: (
        <div className="icon-wrapper">
          <CircleExclamaion />
        </div>
      ),
      async onOk() {
        updateStatus(id, {
          onSuccess: () => {
            // Updating the status in session storage
            const screenDataKey = "ScreenData_/discounts_1";
            const sessionData = JSON.parse(
              sessionStorage.getItem(screenDataKey)
            );

            if (sessionData) {
              const updatedData = sessionData.map((record) =>
                record?.id === id?.id
                  ? {
                      ...record,
                      status:
                        record.status === DISCOUNT_STATUS.ACTIVE
                          ? DISCOUNT_STATUS.INACTIVE
                          : DISCOUNT_STATUS.ACTIVE,
                    }
                  : record
              );
              sessionStorage.setItem(
                screenDataKey,
                JSON.stringify(updatedData)
              );
            }
            notificationService.success(t("statusUpdated"));
          },
          onError: (error) => {
            notificationService.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : t("errorUpdatingStatus")
            );
          },
        });
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <OkBtn />
          <CancelBtn />
        </>
      ),
    });
  };

  return (
    <div className="customerWrapper">
      <CustomRow>
        <CustomColumn lg={24} md={24} sm={24} xs={24}>
          <CustomGridView
            columns={columns}
            listingApiRoute={SERVICE_URL.DISCOUNT.DISCOUNT_API}
            pageTitle={t("discountListing")}
            titleButton={true}
            btnText={t("addNewDiscount")}
            btnAction={() => {
              navigate(authenticatedRoutes.ADD);
            }}
            searchBar={true}
            loadingStates={updatingStatus || deletingDiscount}
            deletedItems={deletedItems}
          />
        </CustomColumn>
      </CustomRow>
    </div>
  );
};

export default Discounts;
