import React, { memo } from "react";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";
import CustomSkeleton from "../CustomSkeleton";
import "./CustomStatusBadge.less";

const CustomStatusBadge = ({ status, contractStatus = [], loading }) => {
  const { t } = useTranslation();
  const getStatusProperties = (statusValue) => {
    const statusObj = contractStatus.find(
      (status) => status.value === statusValue
    );
    return statusObj
      ? {
          label: statusObj.label,
          className: statusObj.className,
        }
      : { label: t("na"), className: "status-unknown" };
  };

  const { label, className } = getStatusProperties(status);

  return (
    <>
      {loading ? (
        <CustomSkeleton length={1} type="para" />
      ) : (
        <span className={`customStatusBadge`}>
          <Badge count={label} className={`${className}`} />
        </span>
      )}
    </>
  );
};

export default memo(CustomStatusBadge);
