export const adminPermissionList = [
  // Dashboard permissions
  "view_dashboard",
  "list_dashboard",
  "create_dashboard",
  "update_dashboard",
  "delete_dashboard",

  // Customers permissions
  "view_customers",
  "list_customers",
  "create_customers",
  "update_customers",
  "delete_customers",

  // Products permissions
  "view_products",
  "list_products",
  "create_products",
  "update_products",
  "delete_products",

  // Orders permissions
  "view_orders",
  "list_orders",
  "create_orders",
  "update_orders",
  "delete_orders",

  // Invoices permissions
  "view_invoices",
  "list_invoices",
  "create_invoices",
  "update_invoices",
  "delete_invoices",

  // Reports permissions
  "view_reports",
  "list_reports",
  "create_reports",
  "update_reports",
  "delete_reports",

  // Product Catalog Permissions
  "create_product_catalog",
  "update_product_catalog",
  "delete_product_catalog",

  // Account
  "create_account",
  "update_account",
  "delete_account",

  // Discounts permissions
  "view_discounts",
  "list_discounts",
  "create_discounts",
  "update_discounts",
  "delete_discounts",
];

export const customerPermissionList = [
  // Account
  "view_account",
  "list_account",

  // Product Catalog permissions
  "view_product_catalog",
  "list_product_catalog",

  ,
  // Orders permissions
  "view_orders",
  "list_orders",

  // Invoices permissions
  "view_invoices",
  "list_invoices",

  // Business Details permissions
  "view_business_details",
  "list_business_details",
  "create_business_details",
  "update_business_details",
  "delete_business_details",

  // Quick Order permissions
  "view_quick_order",
];
