import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { unAuthenticatedRoutes } from "utils/Constants/routes.constant";
import UnAuthorized from "screens/UnAuthorized";
import {
  adminPermissionList,
  customerPermissionList,
} from "utils/Constants/Permissions.constants";
import { USER_TYPE } from "utils/Constants/Constant";

/**
 * @param {{ children: React.ReactNode, requiredPermissions?: string[] }} props
 * @returns
 */
const ProtectedRoute = ({ children, requiredPermissions = [] }) => {
  const { accessToken, userObj } = useAuth();

  // Redirecting to login if there's no accessToken
  if (!accessToken) {
    return <Navigate to={`/`} replace />;
  }

  // If no required permissions, allow access to that route
  if (requiredPermissions.length === 0) {
    return children;
  }

  // Checking if the user has at least one of the required permissions
  // const userPermissions = userObj?.permissions || [];

  const userPermissions =
    userObj?.userRole === USER_TYPE.ADMIN
      ? adminPermissionList
      : userObj?.userRole === USER_TYPE.CUSTOMER
      ? customerPermissionList
      : [];

  const hasPermission = requiredPermissions.some((permission) =>
    userPermissions.includes(permission)
  );

  // Redirect to unauthorized if the user does not have the required permissions
  if (!hasPermission) {
    return <UnAuthorized />;
  }

  return children;
};

export default ProtectedRoute;
