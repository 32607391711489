import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import ScrollProgressBar from "components/CustomComponents/ScrollProgress";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import { useAuth } from "hooks/useAuth";
import { USER_TYPE } from "utils/Constants/Constant";

const { Content } = Layout;
function MainLayout() {
  const { userObj } = useAuth();
  return (
    <div className="layout-sec">
      <Layout>
        {userObj?.userRole === USER_TYPE?.ADMIN && <Sidebar />}
        {/* <Sidebar /> */}
        <Layout>
          <TopHeader />
          <Content className="main-content-wrapper" id="main-content-wrapper">
            {userObj?.userRole === USER_TYPE?.ADMIN && <ScrollProgressBar />}

            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default MainLayout;
