import React, { memo } from "react";
import { Tooltip } from "antd";
import "./CustomTooltip.less";

const CustomTooltip = (props) => {
  const { overlayClassName, className, ...restProps } = props;

  return (
    <Tooltip
      zIndex="11"
      overlayClassName={`${
        overlayClassName ? overlayClassName : ""
      } tooltipStyling`}
      {...restProps}
      className={`${className ? className : ""} gx-mb-0 tooltipStyling`}
    ></Tooltip>
  );
};

export default memo(CustomTooltip);
