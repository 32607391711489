import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { fetchAPI } from "services/api.Service";
import { QUERY_KEYS } from "utils/Constants/QueryKeys.constants";

export const useFetchTableListing = (searchParams, listingApiRoute) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TABLE_LISTING, searchParams, listingApiRoute],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: listingApiRoute,
        queryParams: searchParams,
        signal,
      }),
    // placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    staleTime: 0,
    cacheTime: 0,
  });
};
