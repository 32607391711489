import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { fetchAPI } from "services/api.Service";
import { QUERY_KEYS } from "utils/Constants/QueryKeys.constants";

// FETCH DISCOUNT
export const useFetchDiscount = (params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DISCOUNT, params],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.DISCOUNT.DISCOUNT_API,
        queryParams: params,
        data: {},
        signal,
      }),
    placeholderData: keepPreviousData,
  });
};

// FETCH DISCOUNT BY ID
export const useFetchDiscountById = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DISCOUNT, id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.DISCOUNT.DISCOUNT_API}/${id}`,
        queryParams: {},
      }),
    enabled: !!id,
  });
};

// ADD DISCOUNT
export const useAddDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.DISCOUNT.DISCOUNT_API,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.DISCOUNT]);
    },
  });
};

// UPDATE DISCOUNT
export const useUpdateDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.DISCOUNT.DISCOUNT_API}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      // queryClient.invalidateQueries([QUERY_KEYS.DISCOUNT]);
    },
  });
};

// DELETE DISCOUNT
export const useDeleteDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.DISCOUNT.DISCOUNT_API}/${id}`,
        queryParams: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.DISCOUNT]);
    },
  });
};

// ADD DISCOUNT
export const useDiscountAssign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.DISCOUNT.DISCOUNT_ASSIGN_API,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.DISCOUNT]);
    },
  });
};

// REMOVE DISCOUNT
export const useDiscountUnAssign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.DISCOUNT.DISCOUNT_UNASSIGN_API,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.DISCOUNT]);
    },
  });
};
