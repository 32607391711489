import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "layout";
import {
  authenticatedRoutes,
  unAuthenticatedRoutes,
} from "utils/Constants/routes.constant";
import { useAuth } from "hooks/useAuth";
import { USER_TYPE } from "utils/Constants/Constant";

import FullScreenLoader from "components/CustomComponents/FullScreenLoader";
import ProtectedRoute from "./ProtectedRoute";
import Discounts from "screens/Discounts";
import DiscountDetails from "screens/Discounts/Detail";
import AddEditDiscounts from "screens/Discounts/AddEdit";

const LoginSignup = lazy(() => import("screens/Auth"));
const SignUpComplete = lazy(() => import("screens/Auth/SignUp/SignUpComplete"));
const ResetPassword = lazy(() => import("screens/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("screens/Auth/ForgotPassword"));
const VerifyEmail = lazy(() => import("screens/Auth/VerifyEmail"));

const Dashboard = lazy(() => import("screens/Dashboard"));
const Customers = lazy(() => import("screens/Customers"));
const CustomerDetails = lazy(() => import("screens/Customers/Detail"));
const AddEditCustomers = lazy(() => import("screens/Customers/AddEdit"));
const Products = lazy(() => import("screens/Products"));
const AddEditProducts = lazy(() => import("screens/Products/AddEdit"));
const ProductCatalog = lazy(() => import("screens/ProductCatalog"));
const Checkout = lazy(() => import("screens/Checkout"));
const ProductCatalogDetails = lazy(() =>
  import("screens/ProductCatalog/Detail")
);
const Orders = lazy(() => import("screens/Orders"));
const OrderDetails = lazy(() => import("screens/Orders/Detail"));
const Invoices = lazy(() => import("screens/Invoices"));
const InvoiceDetails = lazy(() => import("screens/Invoices/Detail"));
const Reports = lazy(() => import("screens/Reports"));
const BusinessDetails = lazy(() => import("screens/BusinessDetails"));
const Accounts = lazy(() => import("screens/Accounts"));

const NotFound = lazy(() => import("screens/NotFound"));
const UnAuthorized = lazy(() => import("screens/UnAuthorized"));

const PublicRoute = ({ children }) => {
  const { accessToken, userObj } = useAuth();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const verifyEmailToken = queryParams.get("token");

  if (accessToken && !verifyEmailToken)
    return userObj?.userRole === USER_TYPE?.ADMIN ? (
      <Navigate to={`${authenticatedRoutes.DASHBOARD}`} replace />
    ) : (
      <Navigate to={`${authenticatedRoutes.PRODUCT_CATALOGS}`} replace />
    );
  return children;
};

function AppRoutes() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        {/* UNAUTHENTICATED ROUTES */}
        <Route>
          <Route
            path={`${unAuthenticatedRoutes.AUTHSCREEN}`}
            element={
              <PublicRoute>
                <LoginSignup />
              </PublicRoute>
            }
          />

          <Route
            path={`${unAuthenticatedRoutes.SIGNUP_COMPLETE}`}
            element={
              <PublicRoute>
                <SignUpComplete />
              </PublicRoute>
            }
          />

          <Route
            path={`${unAuthenticatedRoutes.FORGOT_PASSWORD}`}
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          <Route
            path={`${unAuthenticatedRoutes.RESET_PASSWORD}`}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />

          <Route
            path={`${unAuthenticatedRoutes.VERIFY_EMAIL}`}
            element={
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
            }
          />
        </Route>

        {/* AUTHENTICATED ROUTES */}
        <Route element={<MainLayout />}>
          {/* DASHBOARD  */}
          <Route
            path={`${authenticatedRoutes.DASHBOARD}`}
            element={
              <ProtectedRoute
                requiredPermissions={["view_dashboard", "list_dashboard"]}
              >
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* PRODUCT CATALOG  */}
          <Route path={`${authenticatedRoutes.PRODUCT_CATALOGS}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_product_catalog",
                    "list_product_catalog",
                    "create_product_catalog",
                    "updated_product_catalog",
                  ]}
                >
                  <ProductCatalog />
                </ProtectedRoute>
              }
            />{" "}
            <Route path={`${authenticatedRoutes.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <ProductCatalogDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* ORDER  */}
          <Route path={`${authenticatedRoutes.ORDERS}`}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Orders />
                </ProtectedRoute>
              }
            />

            <Route path={`${authenticatedRoutes.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <OrderDetails />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path={`${authenticatedRoutes.NEW_ORDER}`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Checkout />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path={`${authenticatedRoutes.EDIT}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Checkout />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* QUICK ORDER  */}
          <Route path={`${authenticatedRoutes.QUICK_ORDER}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["view_quick_order"]}>
                  <Checkout />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* INVOICE  */}
          <Route path={`${authenticatedRoutes.INVOICES}`}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Invoices />
                </ProtectedRoute>
              }
            />

            <Route path={`${authenticatedRoutes.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <InvoiceDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* REPORT  */}
          <Route path={`${authenticatedRoutes.REPORTS}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={["view_reports", "list_reports"]}
                >
                  <Reports />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CHECKOUT  */}
          <Route path={`${authenticatedRoutes.CHECKOUT}`}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* BUSINESS DETAIL  */}
          <Route path={`${authenticatedRoutes.BUSINESS_DETAILS}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_business_details",
                    "list_business_details",
                  ]}
                >
                  <BusinessDetails />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* ACCOUNT  */}
          <Route path={`${authenticatedRoutes.ACCOUNT}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_account",
                    "list_account",
                    "create_account",
                    "updated_account",
                  ]}
                >
                  <Accounts />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CUSTOMER  */}
          <Route path={`${authenticatedRoutes.CUSTOMERS}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={["view_customers", "list_customers"]}
                >
                  <Customers />
                </ProtectedRoute>
              }
            />

            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_customers",
                    "list_customers",
                    "create_customers",
                    "update_customers",
                    "delete_customers",
                  ]}
                >
                  <AddEditCustomers />
                </ProtectedRoute>
              }
            />

            <Route path={`${authenticatedRoutes.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute
                    requiredPermissions={["view_customers", "list_customers"]}
                  >
                    <CustomerDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* PRODUCTS  */}
          <Route path={`${authenticatedRoutes.PRODUCTS}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={["view_products", "list_products"]}
                >
                  <Products />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_products",
                    "list_products",
                    "create_products",
                    "update_products",
                    "delete_products",
                  ]}
                >
                  <AddEditProducts />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${authenticatedRoutes.EDIT}/:id`}
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_products",
                    "list_products",
                    "create_products",
                    "update_products",
                    "delete_products",
                  ]}
                >
                  <AddEditProducts />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* DISCOUNTS  */}
          <Route path={`${authenticatedRoutes.DISCOUNTS}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={["view_discounts", "list_discounts"]}
                >
                  <Discounts />
                </ProtectedRoute>
              }
            />

            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_discounts",
                    "list_discounts",
                    "create_discounts",
                    "update_discounts",
                    "delete_discounts",
                  ]}
                >
                  <AddEditDiscounts />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${authenticatedRoutes.EDIT}/:id`}
              element={
                <ProtectedRoute
                  requiredPermissions={[
                    "view_discounts",
                    "list_discounts",
                    "create_discounts",
                    "update_discounts",
                    "delete_discounts",
                  ]}
                >
                  <AddEditDiscounts />
                </ProtectedRoute>
              }
            />

            <Route path={`${authenticatedRoutes.DETAIL}/:id`}>
              <Route
                index
                element={
                  <ProtectedRoute
                    requiredPermissions={["view_discounts", "list_discounts"]}
                  >
                    <DiscountDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* UNAUTHORIZED */}
        <Route
          path={unAuthenticatedRoutes.UNAUTHORIZED}
          element={<UnAuthorized />}
        />

        {/* 404  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
